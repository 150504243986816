import LogoLight from "../images/icon-logo-light.png";
import LogoDark from "../images/icon-logo-dark.png";
import LogoMLight from "../images/logo-light.png";
import LogoMDark from "../images/logo-dark.png";
import moment from "moment";
import M from "materialize-css";
require("moment-timezone");

// moment.tz.setDefault("Europe/Moscow");

export const SERVER_SITE = "https://api.nott.one"; // "http://syfer.test:8888"; 

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const truncate = (text, length) => {
  let fragment = text ? text.substring(0, length) : "";
  return fragment.length !== text.length ? fragment + "..." : fragment;
};

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export function toTimeZone(time, zone) {
  let format = "DD.MM.YYYY HH:mm";
  return moment(time).tz(zone).format(format);
}

export function setTheme(theme = "light") {
  const themes = {
    light: {
      "--logo-url": "url(" + LogoMLight + ")",
      "--logo-m-url": "url(" + LogoMLight + ")",
      "--bg-color": "#f4f5f7",
      "--bg-color-transparent": "rgba(255, 255, 253, 0.7)",
      "--theme-color": "#f4f5f7",
      "--bg-hover": "rgba(0, 0, 0, 0.05)",
      "--surface-bg-color": "#fffffc",
      "--divider-color": "#d9dce4",
      "--btn-bg-color": "#3d50fa",
      "--btn-bg-hover-color": "#2a3ffa",
      "--disabled-bg-color": "#d9dce1",
      "--text-primary-color": "#fffffc",
      "--text-secondary-color": "#030303",
      "--text-tertiary-color": "#a5acbe",
      "--alert-color": "#ffbe08",
      "--error-color": "#ff0c0c",
      "--success-color": "#57d9b7",
      "--accent-color": "#3d50fa",
      "--map-filter": "none",
      "--image-theme-filter": "none",
      "--pastel-shadow-color": "0px 0px 7px 1px rgba(217, 220, 228, 0.6)",
    },
    dark: {
      "--logo-url": "url(" + LogoMDark + ")",
      "--logo-m-url": "url(" + LogoMDark + ")",
      "--bg-color": "#1B1C22",
      "--bg-color-transparent": "rgba(37, 38, 44, 0.7)", // 'rgba(25, 26, 31, 0.7)
      "--theme-color": "#1a1125",
      "--bg-hover": "rgba(255, 255, 255, 0.05)",
      "--surface-bg-color": "#25262C",
      "--divider-color": "#9F9FA0", // "#31283f",
      "--btn-bg-color": "#3d50fa",
      "--btn-bg-hover-color": "#2a3ffa",
      "--disabled-bg-color": "#373841", // '#3f334b'
      "--text-primary-color": "#fffffc",
      "--text-secondary-color": "#fffffc",
      "--text-tertiary-color": "#9F9FA0",
      "--alert-color": "#ffbe08",
      "--error-color": "#bc0c0d",
      "--success-color": "#43a188",
      "--accent-color": "#5061fa",
      "--map-filter":
        "invert(95%) hue-rotate(180deg) grayscale(0.5) brightness(110%)",
      "--image-theme-filter": "invert(90%) hue-rotate(180deg)",
      "--pastel-shadow-color": "0px 0px 7px 1px rgba(19, 20, 23, 0.6)",
    },
  };

  if (theme !== "light" && theme !== "dark") {
    theme = "light";
  }

  Object.entries(themes[theme]).forEach(([key, value]) => {
    document.documentElement.style.setProperty(key, value);
  });

  // document.querySelector("meta[name=theme-color]").setAttribute('content', themes[theme]['--theme-color']);
  localStorage.setItem("app_theme", theme);
}

export function applyTheme() {
  const theme = localStorage.getItem("app_theme");
  if (!theme) {
    setTheme("light");
    return;
  }
  setTheme(theme);
}

export function getTheme() {
  const theme = localStorage.getItem("app_theme");
  if (!theme) {
    return "light";
  }
  return theme;
}

export function setSawWelcomeModal(value) {
  localStorage.setItem("app_saw_welcome_modal", value);

  return 1;
}

export function getSawWelcomeModal() {
  const saw = localStorage.getItem("app_saw_welcome_modal");
  if (!saw) {
    return 0;
  }
  return saw;
}

export function formatNumber(x, noFloat = false) {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  if (noFloat) {
    return parts[0];
  }
  return parts.join(",");
}

export function toDate(dateStr) {
  const [day, month, year] = dateStr.split(".");
  return new Date(year, month - 1, day);
}

export function abbrNum(number, decPlaces) {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  var abbrev = [" т", " м", " млрд", " трлн"];

  // Go through the array backwards, so we do the largest first
  for (var i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    var size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number == 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number = formatNumber(number);
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return number;
}

export function getOriginUrl() {
  let href = document.location.href;
  let referrer = document.referrer;
  if (window.parent != window) {
    return referrer;
  } else {
    return href;
  }
}

export function getSelectValues(select) {
  var result = [];
  var options = select && select.options;
  var opt;

  for (var i = 0, iLen = options.length; i < iLen; i++) {
    opt = options[i];
    if (opt.selected) {
      result.push(opt.value || opt.text);
    }
  }
  // console.log(result);
  return result;
}

export function parseForm(htmlForm) {
  let sendFormData = new FormData();
  for (let i = 0; i < htmlForm.length; i++) {
    if (htmlForm[i].getAttribute("type") == "file") {
      // console.log(htmlForm[i].files[0]);
      // sendFormData.append(
      //   htmlForm[i].getAttribute("name"),
      //   htmlForm[i].files[0]
      // );

      for (let j = 0; j < htmlForm[i].files.length; j++) {
        sendFormData.append(
          htmlForm[i].getAttribute("name") + "[]",
          htmlForm[i].files[j]
        );
      }
    } else if (htmlForm[i].getAttribute("type") == "checkbox") {
      if (htmlForm[i].checked) {
        sendFormData.append(htmlForm[i].getAttribute("name"), 1);
      } else {
        sendFormData.append(htmlForm[i].getAttribute("name"), 0);
      }
    }

    if (
      htmlForm[i].getAttribute("name") &&
      htmlForm[i].getAttribute("type") != "file" &&
      htmlForm[i].getAttribute("type") != "checkbox"
    ) {
      if (htmlForm[i].hasAttribute("multiple")) {
        let multipleSelectArray = getSelectValues(htmlForm[i]);
        sendFormData.append(
          htmlForm[i].getAttribute("name"),
          multipleSelectArray
        );
      } else {
        sendFormData.append(
          htmlForm[i].getAttribute("name"),
          htmlForm[i].value
        );
      }
      // sendFormData.append('uploaded_file', uploadForm[0].files[0]);
    }
  }

  // console.log(sendFormData);
  // for (var pair of sendFormData.entries()) {
  //   console.log(pair[0] + ", " + pair[1]);
  // }

  return sendFormData;
}

export function fallbackCopyTextToClipboard(
  text,
  message = "Скопировано!",
  container = "body"
) {
  let textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  // document.body.appendChild(textArea);
  let containerElement = document.querySelector(container);
  containerElement.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    let successful = document.execCommand("copy");
    let msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
    if (successful) {
      M.toast({ html: message });
    } else {
      M.toast({ html: "Не скопировано!" });
    }
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
    M.toast({ html: "Не удалось скопировать, попробуйте вручную" });
  }

  containerElement.removeChild(textArea);
}

export function copyTextToClipboard(
  text,
  message = "Скопировано!",
  container = "body"
) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, message, container);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
      M.toast({ html: message });
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
      M.toast({ html: "Не удалось скопировать, попробуйте вручную" });
    }
  );
}

export function copy(obj) {
  function copyProps(clone) {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        clone[key] = copy(obj[key]);
      }
    }
  }

  /**
   * Создание иммутабельной копии объекта
   * @return {Object}
   */
  function cloneObj() {
    let clone = {};
    copyProps(clone);
    return clone;
  }

  /**
   * Создание иммутабельной копии массива
   * @return {Array}
   */
  function cloneArr() {
    return obj.map(function (item) {
      return copy(item);
    });
  }

  /**
   * Создание иммутабельной копии Map
   * @return {Map}
   */
  function cloneMap() {
    let clone = new Map();
    for (let [key, val] of obj) {
      clone.set(key, copy(val));
    }
    return clone;
  }

  /**
   * Создание иммутабельной копии Set
   * @return {Set}
   */
  function cloneSet() {
    let clone = new Set();
    for (let item of obj) {
      clone.add(copy(item));
    }
    return clone;
  }

  /**
   * Создание иммутабельной копии функции
   * @return {Function}
   */
  function cloneFunction() {
    let clone = obj.bind(this);
    copyProps(clone);
    return clone;
  }

  // Получение типа объекта
  let type = Object.prototype.toString.call(obj).slice(8, -1).toLowerCase();

  // Возвращаем копию в зависимости от типа исходных данных
  if (type === "object") return cloneObj();
  if (type === "array") return cloneArr();
  if (type === "map") return cloneMap();
  if (type === "set") return cloneSet();
  if (type === "function") return cloneFunction();

  return obj;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getQueryParam(name) {
  let url = new URL(window.location.href);
  let param = url.searchParams.get(name);
  return param;
}

export function isEmpty(obj) {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export function strToNumber(str) {
  str = str.replace(/,/, ".");
  let number = str.replace(/[^\d.-]/g, "");
  return number;
}

export function textWithLinks(text) {
  let outputText = [];

  if (text) {
    text.replace(
      /((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim,
      (m, link, t) => {
        outputText.push(
          link ? (
            <a
              href={(link[0] === "w" ? "//" : "") + link}
              className="iq-link"
              target="_blank"
              rel="noreferrer"
              key={outputText.length}
            >
              {link}
            </a>
          ) : (
            t
          )
        );
      }
    );
  }

  return outputText;
}

export function youtubeVideoIdParser(url) {
  var regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}
