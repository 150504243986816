import "./styles/App.css";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./components/AppRouter";
import { AuthContext } from "./context";
import { useState, useEffect } from "react";
import { getCookie, SERVER_SITE } from "./utils/service";
import Navbar from "./components/UI/Navbar/Navbar";
import RequestService from "./api/RequestService";
import FullScreenLoader from "./components/UI/FullScreenLoader/FullScreenLoader";
import { ConfirmDialogProvider } from "./context/ConfirmDialog";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isFullScreenLoading, setFullScreenLoading] = useState(false);
  const [globalError, setGlobalError] = useState("");

  window.nott = {
    info: {
      support_email: "support@nott.one",
      server_site: SERVER_SITE,
      version: "v 0.6.3 beta",
      vkid_redirect_url: "https://nott.one/settings/verification/vkid", //  "http://localhost"
      vkid_auth_redirect_url: "https://nott.one/login/vkid", //  "http://localhost"
    },
  };

  useEffect(() => {
    // console.info("запуск проверки");
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      // console.info("начинаем загрузку");
      setLoading(true);
      if (getCookie("nott_xsrf_token")) {
        setIsAuth(true);
        setLoading(false);
        // console.info("прекращаем загрузку");
        return;
      }

      await RequestService.refreshTokens();
      if (getCookie("nott_xsrf_token")) {
        setIsAuth(true);
        setLoading(false);
        return;
      }

      setIsAuth(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setGlobalError(error);
      // console.log(error);
    }
  };

  // console.info('отрисовка');

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
        isLoading,
        setLoading,
        isFullScreenLoading,
        setFullScreenLoading,
        globalError,
        setGlobalError,
      }}
    >
      <ConfirmDialogProvider>
        <BrowserRouter>
          <Navbar />
          {/* {isAuth ? <Navbar /> : null} */}
          <FullScreenLoader state={isFullScreenLoading} />
          <AppRouter />
        </BrowserRouter>
      </ConfirmDialogProvider>
    </AuthContext.Provider>
  );
}

export default App;
